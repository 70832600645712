<template>
  <popup
      title="Как правильно настроить Keitaro для Newslab"
      html-id="guideKeitaro"
      @close="$emit('close')"
  >
    <p>В этом материале мы поможем тебе правильно настроить постбеки <strong>Keitaro</strong>. Наша пошаговая инструкция позволит правильно настроить все необходимые данные легко и просто. Начнем!</p>
    <h3>1. Добавляем партнерку</h3>
    <p>Для начала переходим во вкладку <strong>“Партнерские сети”</strong>, где и будем создавать партнерку. Сперва во вкладке <strong>“Шаблон”</strong> выбираем - <strong>“Не использовать”</strong>. Далее во вкладке <strong>“Название”</strong> пишем, например - Newslab. Во вкладке “Параметры офферов” пишем - sid4={subid}, а во вкладке <span>“Postback URL”</span> вводим - <span style="color: #1F8EFA">http://185.237.224.142/3b27945/postback?subid={sid4}</span>. После добавляем в ссылку параметры, которые нам необходимы.</p>
    <p>
      Краткий гайд: <br>
      <strong>{click_id}</strong> - номер лида в системе, <br>
      <strong>{status}</strong> - статус лида (pending, approved, rejected, trash), <br>
      <strong>{payout}</strong> - сумма отчислений вебмастеру, <br>
      <strong>{currency}</strong> - валюта
    </p>
    <p>В итоге получаем данные как на скриншоте ниже:</p>
    <img src="img/guide_keitaro_1.png" alt="1">
    <p>ВАЖНО правильно заполнить такие параметры:</p>
    <img src="img/guide_keitaro_2.png" alt="2">
    <img src="img/guide_keitaro_3.png" alt="3">
    <h3>2. Создаем поток на стороне партнерки</h3>
    <p>После проделанных действий мы переходим в админку на нашем сайте, где нажимаем на вкладку <strong>“Потоки”</strong>, после чего нажимаем <strong>“Добавить поток”</strong>. Есть и второй способ - переходим во вкладку <strong>“Новости”</strong> и на понравившейся новости нажимаем <strong>“+”</strong>.  Не забудьте также активировать графу “Отправлять PostBack”, а в последнем пункте “PostBack URL” вставляем ссылку из пункта 1. В итоге получаем данные как на скриншоте ниже:</p>
    <img src="img/guide_keitaro_4.png" alt="4">
    <p>http://185.237.224.142/3b27945/postback?subid={sid4}&#38;payout={payout}&#38;currency={currency}&#38;status={status}&#38;from=novostnik</p>
    <h3>3. Создаем оффер</h3>
    <p>Для этого переходим во вкладку <strong>“Офферы”</strong> и нажимаем <strong>“Создать”</strong>. После этого перед нами появится окно с полями, которые нужно заполнить. В названии пишем, к примеру, <strong>Test постбека 16.02</strong>. Во вкладке <strong>“Партнерская сеть”</strong> выбираем только что нами созданную <strong>Newslab</strong>, а во вкладке URL вписываем ссылку на нашу новость - https://izvestiya24.ru/3439?vt=1&cid=473923. В итоге должно получится как на скриншоте ниже:</p>
    <img src="img/guide_keitaro_5.png" alt="5">
    <p><strong style="color: red">Важно:</strong> использовать лучше именно sid4. <i>Через sid5 осуществляется передача click id для оповещения кейтаро об изменениях статусов лидов</i>.</p>
    <h3>4. Создаем кампанию и поток</h3>
    <p>Для этого переходим во вкладку <strong>“Кампании”</strong>, выбираем уже созданную нами <strong>Newslab</strong> и создаем на нее поток. Для этого нажимаем на вкладку <strong>“Создать поток”</strong> и выставляем необходимые нам параметры. В результате должно получится как на скриншоте ниже:</p>
    <img src="img/guide_keitaro_6.png" alt="6">
    <p>Далее в этом потоке во вкладке <strong>“Схема”</strong> указываем созданный ранее <strong>Оффер</strong> как на скриншоте ниже:</p>
    <img src="img/guide_keitaro_7.png" alt="7">
    <p>Сохраняем кампанию и переходим во вкладку <strong>“Интеграция”</strong>. Мы должны увидеть подобное:</p>
    <img src="img/guide_keitaro_8.png" alt="8">
    <p>Именно эту ссылку <span style="color: #1F8EFA">http://185.237.224.142/FTfT5S</span> в дальнейшем мы и будем использовать как ссылку потока. После этого заходим во вкладку <strong>“Обслуживание”</strong> - выбираем <strong>“Логи”</strong> и смотрим все ли на месте. </p>
    <img src="img/guide_keitaro_9.png" alt="9">
    <p>Если же логов нет, то скорее всего вы упустили какой-нибудь из шагов - еще раз пересмотрите инструкцию и попробуйте снова. </p>
    <p>Также советуем проверить страницу с логами постбэка в кабинете веба новостной витрины:</p>
    <img src="img/guide_keitaro_10.png" alt="10">
    <p>Надеемся, эта инструкция будет вам полезна!</p>
  </popup>
</template>

<script>
import { defineComponent } from "vue";
import Popup from "../../components/Popup";

export default defineComponent({
  components: {
    Popup,
  },
  emits: ["close"],
});
</script>

<style lang="scss" scoped>
* {
  max-width: 800px;
}

p + p {
  margin-top: 20px;
}

p {
  line-height: 24px;
}

ol,
ul {
  margin: 20px 0;
  padding-left: 20px;

  li + li {
    margin-top: 10px;
  }

  li {
    p + p {
      margin-top: 10px;
    }
  }
}

i {
  font-style: italic;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

img {
  margin: 15px 0;
  max-width: 100%;
}

a:not(.sites-link) {
  text-decoration: underline;
  color: #0d95e8;
}

.sites-link {
  color: #ff9c31;
}

h3 {
  font-weight: bold;
  margin: 20px 0;
}
</style>