import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['tooltip', _ctx.className])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _createElementVNode("span", {
      class: _normalizeClass(["tooltip__text", `tooltip__text_${_ctx.position}`])
    }, _toDisplayString(_ctx.text), 3)
  ], 2))
}