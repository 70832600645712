
import { defineComponent } from "vue";
import axios from "axios";

import Popup from "../../components/Popup.vue";
import TextInput from "../../components/TextInput.vue";
import Dropdown from "../../components/Dropdown.vue";
import Switcher from "../../components/Switcher.vue";
import Helper from "../../components/Helper.vue";
import SubsetInput from "../../components/SubsetInput.vue";

import getCampaignUrl from "../../modules/get-campaign-url";

import { PageTypes } from "../../modules/enums";

export default defineComponent({
  components: { Popup, TextInput, Dropdown, Switcher, Helper, SubsetInput },
  props: {
    campaign: Object,
    domains: Array as any,
    domains_parked: Array as any,
  },
  emits: ["close", "saved", "update:campaign"],
  data() {
    return {
      loading: false,
      errors: {} as { [key: string]: string | null },
      pageTypes: [],
      news: [],
      designs: [],
      geos: [] as any[],
      integrations: [],
      isNewsDeleted: false,
      isDetailsTextOpened: false,
      exampleLink:
        "http://185.237.224.142/3b27945/postback?subid={sid4}&payout={payout}&currency={currency}&status={status}&from=novostnik",
    };
  },
  watch: {
    news() {
      if (this.checkIsNewsDeleted(this.campaign?.news_id) === undefined) {
        this.isNewsDeleted = true;
        if (!this.news.length && !this.isGeoExist) {
          this.$emit("update:campaign", {
            ...this.campaign,
            geo_id: null,
            article_name: null,
          });
        }
      }
    },
  },
  computed: {
    isGeoExist() {
      return this.geos.find((g) => g.id === this.campaign?.geo_id);
    },
    campaignUrl() {
      return getCampaignUrl(this.campaign, this.domains, this.domains_parked);
    },
    isExtraMonetization() {
      return (
        Number(this.campaign!.page_type_id) === PageTypes.ExtraMonetization
      );
    },
    isOnlyNews() {
      return (
        Number(this.campaign!.page_type_id) === PageTypes.OnlyNews
      );
    },
    effectiveParkedDomains() {
      return this.domains_parked.filter(
        (d: any) => d.geo_id == this.campaign?.geo_id
      );
    },
    domainName(): string {
      return this.campaign?.domain_parked_id
        ? ""
        : this.domains?.find((d: any) => d.geo_id == this.campaign?.geo_id)
            ?.name;
    },
  },
  created() {
    axios
      .get("/api/page-types/lookup")
      .then((response) => (this.pageTypes = response.data));
    axios
      .get("/api/geos/lookup")
      .then((response) => (this.geos = response.data));
    axios
      .get("/api/designs/lookup")
      .then((response) => (this.designs = response.data));
    this.filterNews("", this.campaign?.geo_id);
    this.filterIntegrations("");
  },
  methods: {
    getNewsByGeo(id: number) {
      this.filterNews("", id);
    },
    checkIsNewsDeleted(news_id: number) {
      return this.news.find((n: any) => news_id === n.id);
    },
    filterNews(query: string, geo_id: any) {
      axios
        .get(`/api/news/lookup`, {
          params: {
            q: JSON.stringify({
              geo_id,
              query,
            }),
          },
        })
        .then((response) => (this.news = response.data));
    },
    filterIntegrations(query: string) {
      axios
        .get(`/api/integrations/lookup`, {
          params: {
            q: JSON.stringify({ query }),
          },
        })
        .then((response) => (this.integrations = response.data));
    },
    emitPatch(patch: any) {
      this.$emit("update:campaign", {
        ...this.campaign,
        ...patch,
        news_id:
          patch.geo_id ||
          this.campaign?.page_type_id === 3 ||
          patch.page_type_id === 3 || this.campaign?.page_type_id === 4 || patch.page_type_id === 4
            ? null
            : patch.news_id || this.campaign?.news_id,
        domain_id:
          patch.geo_id || this.campaign?.geo_id
            ? this.domains?.find(
                (d: any) => d.geo_id == patch.geo_id || this.campaign?.geo_id
              )!.id
            : null,
        domain_parked_id:
          patch.geo_id || patch.page_type_id
            ? null
            : patch.domain_parked_id || this.campaign?.domain_parked_id,
        has_extra_monetization:
          patch.page_type_id === PageTypes.ExtraMonetization || patch.page_type_id === PageTypes.OnlyNews
            ? false
            : patch.has_extra_monetization !== undefined
            ? patch.has_extra_monetization
            : this.campaign!.has_extra_monetization,
        has_popup:
          patch.page_type_id === PageTypes.ExtraMonetization || patch.page_type_id === PageTypes.OnlyNews
            ? false
            : patch.has_popup !== undefined
            ? patch.has_popup
            : this.campaign?.has_popup,
        has_push:
          patch.page_type_id === PageTypes.ExtraMonetization || patch.page_type_id === PageTypes.OnlyNews
            ? false
            : patch.has_push !== undefined
            ? patch.has_push
            : this.campaign?.has_push,
      });
      if (patch.geo_id !== undefined) {
        this.getNewsByGeo(patch.geo_id);
        this.errors.domain_parked_id = null;
      }
      if (patch.news_id) {
        this.isNewsDeleted = false;
      }
      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async saveCampaign() {
      this.loading = true;
      try {
        const campaign = { ...this.campaign, name: this.campaign?.name.trim() };
        await axios.post("/api/campaigns", campaign);
        this.$emit("saved");
        ($ as any).fancybox.close();
        setTimeout(() => (this.loading = false), 1000);
      } catch (e) {
        this.loading = false;
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }
    },
  },
});
