import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "fieldset" }
const _hoisted_2 = ["disabled", "name", "value", "type", "readonly"]
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 1,
  class: "input__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _renderSlot(_ctx.$slots, "prepend"),
    _createElementVNode("input", {
      disabled: _ctx.disabled,
      class: _normalizeClass([
        'input',
        {
          _focus: _ctx.focused || _ctx.readonly,
          _focusout: !_ctx.focused,
          _valid: _ctx.value,
          _required: _ctx.required,
        },
      ]),
      name: `text-input-${_ctx.label}`,
      value: _ctx.value,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:value', $event.target.value))),
      type: _ctx.type,
      onFocus: _cache[1] || (_cache[1] = ($event: any) => (_ctx.focused = true)),
      onFocusout: _cache[2] || (_cache[2] = ($event: any) => (_ctx.focused = false)),
      readonly: _ctx.readonly
    }, null, 42, _hoisted_2),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "label",
          for: `text-input-${_ctx.label}`
        }, _toDisplayString(_ctx.label), 9, _hoisted_3))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "append"),
    (_ctx.error)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.error), 1))
      : _createCommentVNode("", true)
  ]))
}