
import { defineComponent } from "vue";
import axios from "axios";
import moment, { Moment } from "moment";

import AppPage from "../../components/AppPage.vue";
import Preloader from "../../components/Preloader.vue";
import DataTable from "../../components/DataTable.vue";
import DateRangeInput from "../../components/DateRangeInput.vue";
import TextInput from "../../components/TextInput.vue";
import Tooltip from "../../components/Tooltip.vue";
import Postbacks from "./Postbacks.vue";
import SubsetInput from "@/components/SubsetInput.vue";

import Editor from "./Editor.vue";
import Archiver from "./Archiver.vue";
import Activator from "./Activator.vue";
import GuidePopup from "./GuidePopup.vue";

import getCampaignUrl from "../../modules/get-campaign-url";
import { Sorting, Pagination, Option } from "../../modules/types";
import { PageTypes } from "../../modules/enums";

interface DataModel {
  query: Query;
  loading: boolean;
  rows: any[];
  domains: any[];
  domains_parked: Option[];
  totalRows: number;
  previousQuery: Query | null;
  campaignToEdit: any;
  campaignToArchive: any;
  campaignToActivate: any;
  extraMonetizationTypeId: number;
  clearNewsTypeId: number;
  isPostbacksShown: boolean;
  isDeletedShown: boolean;
  isArchived: boolean;
  isDeleted: boolean;
  geos: any[];
  geosLookup: any[];
  isGuideOpened: boolean;
}

interface Query {
  period: Moment[];
  search: string;
  pagination: null | Pagination;
  sorting: Sorting;
  geo_ids: number[];
}

function getStoredQuery(previous = false): Query | null {
  const query = localStorage.getItem(
    previous ? "campaigns.previous_query" : "campaigns.query"
  );
  if (!query) {
    return null;
  }
  const parsed: Query = JSON.parse(query);
  parsed.period = parsed.period.map((x) => moment(x));
  return parsed;
}

function getDefaultQuery(): Query {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    search: "",
    sorting: { column: "updated_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
    geo_ids: [],
  };
}

export default defineComponent({
  components: {
    AppPage,
    Preloader,
    DateRangeInput,
    DataTable,
    TextInput,
    Tooltip,
    Editor,
    Archiver,
    Activator,
    Postbacks,
    SubsetInput,
    GuidePopup,
  },
  data(): DataModel {
    return {
      loading: false,
      query: getDefaultQuery(),
      rows: [],
      domains: [],
      domains_parked: [],
      totalRows: 0,
      previousQuery: getStoredQuery(true),
      campaignToEdit: null,
      campaignToArchive: null,
      campaignToActivate: null,
      extraMonetizationTypeId: PageTypes.ExtraMonetization,
      clearNewsTypeId: PageTypes.OnlyNews,
      isPostbacksShown: false,
      isDeletedShown: false,
      isArchived: false,
      isDeleted: false,
      geosLookup: [],
      geos: [],
      isGuideOpened: false,
    };
  },
  mounted() {
    this.$watch("isArchived", this.load);
    this.$watch("isDeleted", this.load);
    this.$watch("query.sorting", this.load);
    this.$watch("query.pagination", this.load);
  },
  computed: {
    columns() {
      return [
        {
          id: "updated_at",
          name: "Дата потока",
          selected: true,
          sortable: true,
          htmlClass: "streams__table--date",
        },
        {
          id: "name",
          name: "Название потока",
          selected: true,
          sortable: true,
          htmlClass: "streams__table--name",
          type: "text",
        },
        {
          id: "image_filename",
          name: "Картинка",
          selected: true,
          sortable: false,
          htmlClass: "streams__table--image",
        },
        {
          id: "article_name",
          name: "Название статьи",
          selected: true,
          sortable: true,
          htmlClass: "streams__table--h",
          type: "text",
        },
        {
          id: "geo",
          name: "Гео",
          selected: true,
          sortable: false,
          htmlClass: "streams__table--flag",
        },
        {
          id: "buttons",
          name: "",
          selected: true,
          freezed: true,
          sortable: false,
          htmlClass: "streams__table--action",
        },
      ];
    },
  },
  async created() {
    this.getGeos();
    this.load();
  },
  methods: {
    getCampaignUrl,
    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY");
    },
    getGeoISOCode(id: number) {
      return this.geos.find((g) => g.id === id)?.iso_code;
    },
    async getGeos(query = "") {
      const { data } = await axios.get("/api/geos/lookup-for-news", {
        params: { q: JSON.stringify(query) },
      });
      this.geosLookup = data;
    },
    apply() {
      const previousQueryJson = localStorage.getItem("campaigns.query");
      if (previousQueryJson) {
        localStorage.setItem("campaigns.previous_query", previousQueryJson);
        this.previousQuery = getStoredQuery(true);
      }

      const json = JSON.stringify(this.query);
      localStorage.setItem("campaigns.query", json);

      this.load();
    },
    async load() {
      this.loading = true;

      this.rows = [];
      this.totalRows = 0;

      try {
        const [
          { data: campaigns },
          { data: domains },
          { data: domains_parked },
          { data: geos },
        ] = await Promise.all([
          axios.get("/api/campaigns", {
            params: {
              q: JSON.stringify(this.query),
              is_archived: this.isArchived,
              is_deleted: this.isDeleted,
            },
          }),
          axios.get("/api/domains"),
          axios.get("/api/domains-parked/lookup", {
            params: { q: JSON.stringify({ query: "", is_parked: true }) },
          }),
          axios.get("/api/geos"),
        ]);

        this.domains = domains;
        this.domains_parked = domains_parked;
        this.rows = campaigns.rows;
        this.totalRows = campaigns.total;
        this.geos = geos;

        if (!this.rows.length && this.query.pagination!.offset !== 0) {
          this.query.pagination!.offset = 0;
          this.load();
        }
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }

      this.loading = false;
    },
    applyPrevious() {
      const stored = getStoredQuery(true);
      if (stored) {
        this.query = stored;
        this.load();
      }
    },
    clear() {
      this.query = getDefaultQuery();
      this.load();
    },
    updateGeoAndLoad(e: any) {
      this.query.geo_ids = e;
      if (this.query.pagination) {
        this.query.pagination.offset = 0;
      }
    },
    openEditor(row: any) {
      this.campaignToEdit = row || {
        name: "",
        page_type_id: null,
        design_id: 1,
        domain_id: null,
        domain_parked_id: null,
        geo_id: null,
        news_id: null,
        has_extra_monetization: false,
        has_popup: false,
        has_push: false,
        has_postback: false,
        sid1: "",
        sid2: "",
        sid3: "",
        sid4: "",
        postback_url: "",
        integration_ids: null,
      };
    },
    openLink(row: any) {
      window.open(
        getCampaignUrl(row, this.domains, this.domains_parked),
        "_blank"
      );
    },
    toActiveTab() {
      this.isArchived = false;
      this.isPostbacksShown = false;
      this.isDeleted = false;
      this.isDeletedShown = false;
    },
    toArchiveTab() {
      this.isArchived = true;
      this.isPostbacksShown = false;
      this.isDeleted = false;
      this.isDeletedShown = false;
    },
    toPostbackTab() {
      this.isArchived = false;
      this.isPostbacksShown = true;
      this.isDeleted = false;
      this.isDeletedShown = false;
    },
    toDeleted() {
      this.isArchived = false;
      this.isPostbacksShown = false;
      this.isDeleted = true;
      this.isDeletedShown = true;
    },
  },
});
