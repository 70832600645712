
import { defineComponent, PropType } from "@vue/runtime-core";

export default defineComponent({
  props: {
    label: String,
    value: [String, Number],
    type: { type: String, default: () => "text" },
    error: {
      type: String as PropType<string | null | undefined>,
      default: () => undefined,
    },
    disabled: { type: Boolean, default: () => false },
    required: { type: Boolean, default: () => false },
    readonly: { type: Boolean, default: () => false },
  },
  data() {
    return { focused: false };
  },
});
