
import { defineComponent } from "vue";
import axios from "axios";
import Popup from "../../components/Popup.vue";
export default defineComponent({
  components: { Popup },
  emits: ["archived", "close"],
  props: { campaign: Object },
  methods: {
    async archiveCampaign() {
      try {
        await axios.post("/api/campaigns/" + this.campaign!.id + "/archive");
        this.$emit("archived");
        ($ as any).fancybox.close();
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
  },
});
