
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    text: String,
  },
  mounted() {
    $(this.$refs.helper as any)
      .mouseover(function () {
        let text = $(this).data("text");
        let top = $(this).offset()!.top + 10 + "px";
        let left = $(this).offset()!.left + 10 + "px";

        $(this).append(`<div class="helper-text">${text}</div>`);

        $(this).find(".helper-text").css({
          top,
          left,
        });
      })
      .mouseout(function () {
        $(this).find(".helper-text").remove();
      });

    $(window).resize(function () {
      $(".helper-text").remove();
    });
  },
});
