export default function getCampaignUrl(
  campaign: any,
  domains: any[],
  domains_parked: any[],
): string | undefined {
  if (!campaign.id) {
    return;
  }

  if (campaign.geo_id) {
    const url = getBaseUrl(campaign, domains, domains_parked);
    url.searchParams.append("cid", campaign.id);
    if (campaign.sid1) {
      url.searchParams.append("sid1", campaign.sid1);
    }
    if (campaign.sid2) {
      url.searchParams.append("sid2", campaign.sid2);
    }
    if (campaign.sid3) {
      url.searchParams.append("sid3", campaign.sid3);
    }
    if (campaign.sid4) {
      url.searchParams.append("sid4", campaign.sid4);
    }
    return decodeURI(url.toString());
  }

  return "";
}

function getBaseUrl(
  campaign: any,
  domains: any[] = [],
  domains_parked: any[] = []
) {
  const domain = campaign.domain_parked_id
    ? domains_parked.find((dp: any) => dp.id == campaign.domain_parked_id)?.name
    : domains.find((d: any) => d.geo_id == campaign.geo_id)?.name;

  const url = new URL(`https://${domain}`);
  url.pathname =
    campaign.page_type_id === 3 ? "/teasers-feed" : campaign.page_type_id === 4 ? "/news-feed" : `/${campaign.news_id}`;
  if (campaign.page_type_id === 1) {
    url.searchParams.append("vt", "1");
  }
  return url;
}
