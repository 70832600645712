
import { defineComponent } from "vue";
import axios from "axios";
import moment, { Moment } from "moment";

import { Sorting, Pagination, Option, Column } from "../../modules/types";

import DataTable from "../../components/DataTable.vue";
import DateRangeInput from "../../components/DateRangeInput.vue";
import TextInput from "../../components/TextInput.vue";
import SubsetInput from "../../components/SubsetInput.vue";

interface DataModel {
  query: Query;
  loading: boolean;
  rows: any[];
  totalRows: number;
  campaigns: Option[];
  columns: Column[];
}

interface Query {
  period: Moment[];
  search: string;
  campaign_ids: number[];
  pagination: null | Pagination;
  sorting: Sorting;
}

function getDefaultQuery(): Query {
  return {
    period: [moment().endOf("day").subtract(29, "days"), moment().endOf("day")],
    search: "",
    campaign_ids: [],
    sorting: { column: "created_at", direction: "desc" },
    pagination: { limit: 10, offset: 0 },
  };
}

function storeColumns(columns: Column[]) {
  localStorage.setItem("postbacks.columns", JSON.stringify(columns));
}

function getStoredColumns() {
  const json = localStorage.getItem("postbacks.columns");
  if (!json) {
    return allColumns;
  } else {
    const storedColumns = JSON.parse(json).reduce(
      (memo: any, c: any) => ({
        ...memo,
        [c.id]: c,
      }),
      {}
    );
    return allColumns.map((c) =>
      storedColumns[c.id]
        ? {
            ...c,
            selected: storedColumns[c.id].selected,
            freezed: storedColumns[c.id].freezed,
          }
        : c
    );
  }
}

const allColumns: Column[] = [
  {
    id: "created_at",
    name: "Дата",
    selected: true,
    sortable: true,
    freezed: true,
  },
  {
    id: "url",
    name: "Postback URL",
    selected: true,
    sortable: true,
  },
  {
    id: "campaign_id",
    name: "Поток",
    selected: true,
    sortable: false,
  },
  {
    id: "is_sent",
    name: "Статус",
    selected: true,
    sortable: true,
  },
  {
    id: "error",
    name: "Код ошибки",
    selected: true,
    sortable: false,
  },
];

export default defineComponent({
  components: { DataTable, DateRangeInput, TextInput, SubsetInput },
  data(): DataModel {
    return {
      query: getDefaultQuery(),
      loading: false,
      rows: [],
      totalRows: 0,
      campaigns: [],
      columns: getStoredColumns(),
    };
  },
  created() {
    this.load();
    this.getCampaigns();
  },
  mounted() {
    this.$watch("query.period", this.load, { deep: true });
    this.$watch("query.campaign_ids", this.load);
  },
  watch: {
    columns(newColumns) {
      storeColumns(newColumns);
    },
  },
  methods: {
    async load() {
      this.loading = true;
      this.rows = [];
      this.totalRows = 0;
      try {
        const { data } = await axios.get("/api/postbacks", {
          params: { q: JSON.stringify(this.query) },
        });
        this.rows = data.rows;
        this.totalRows = data.total;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
      this.loading = false;
    },

    async getCampaigns(query = "") {
      const { data } = await axios.get("/api/campaigns/lookup", {
        params: { q: JSON.stringify({ query }) },
      });
      this.campaigns = data;
    },

    formatDate(d: string): string {
      return moment(d).format("DD.MM.YYYY HH:MM:SS");
    },
    clear() {
      this.query = getDefaultQuery();
    },
    download() {
      window.open(
        `/api/postbacks/download?q=${encodeURIComponent(
          JSON.stringify(this.query)
        )}`,
        "_blank"
      );
    },
  },
});
