
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: String,
    className: String,
    position: {
      type: String,
      default: "bottom",
      validator(value: string) {
        return ["left", "top", "right", "bottom"].includes(value);
      },
    },
  },
});
